import React, { useMemo, useState } from 'react';

export const PostContext = React.createContext({
	postAnchors: [],
	setPostAnchors: () => {},
});

const PostContextProvider = ({ children }) => {
	const [postAnchors, setPostAnchors] = useState([]);
	const value = useMemo(() => ({ postAnchors, setPostAnchors }), [postAnchors]);

	return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
};

export default PostContextProvider;
