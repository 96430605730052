import React from 'react';
import './src/styles/main.css';
import PostContextProvider from 'context/postContext';
import NavigationContextProvider from 'context/navigationContext';

export const wrapRootElement = ({ element }) => (
    <NavigationContextProvider>
        <PostContextProvider>{element}</PostContextProvider>
    </NavigationContextProvider>
);

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === 'undefined') {
        await import('intersection-observer');
    }
};
