import React, { useMemo, useState } from 'react';

export const NavigationContext = React.createContext({
	navItems: [],
	setNavItems: () => {},
});

const NavigationContextProvider = ({ children }) => {
	const [navItems, setNavItems] = useState([]);

	const value = useMemo(() => ({ navItems, setNavItems }), [navItems]);

	return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

export default NavigationContextProvider;
