exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-affordable-dentist-jsx": () => import("./../../../src/templates/AffordableDentist.jsx" /* webpackChunkName: "component---src-templates-affordable-dentist-jsx" */),
  "component---src-templates-author-jsx": () => import("./../../../src/templates/Author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-browse-dentist-jsx": () => import("./../../../src/templates/BrowseDentist.jsx" /* webpackChunkName: "component---src-templates-browse-dentist-jsx" */),
  "component---src-templates-categories-jsx": () => import("./../../../src/templates/Categories.jsx" /* webpackChunkName: "component---src-templates-categories-jsx" */),
  "component---src-templates-denture-repair-jsx": () => import("./../../../src/templates/DentureRepair.jsx" /* webpackChunkName: "component---src-templates-denture-repair-jsx" */),
  "component---src-templates-emergency-dentist-jsx": () => import("./../../../src/templates/EmergencyDentist.jsx" /* webpackChunkName: "component---src-templates-emergency-dentist-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-same-day-dentures-jsx": () => import("./../../../src/templates/SameDayDentures.jsx" /* webpackChunkName: "component---src-templates-same-day-dentures-jsx" */)
}

